import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { KycMonitoringSearch } from '../models/kyc-monitoring-search';
import { KycMonitoringFilter } from '../models/kyc-monitoring-filter';

@Injectable({ providedIn: 'root' })
export class KycMonitoringService {

    constructor(
        private httpClient: HttpClient
    ) { }

    search(filter: KycMonitoringFilter): Observable<Array<KycMonitoringSearch>> {
        return this.httpClient.post<Array<KycMonitoringSearch>>(`${environment.apiUrl}/kycmonitoring/search`, filter);
    }
    
    export(filter: KycMonitoringFilter): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/kycmonitoring/export`, filter, {
            responseType: 'blob' as 'json'
        });
    }
}
