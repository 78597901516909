import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@app/application/services/state.service';
import { AuthService } from '@app/services/auth.service';
import { MessageService } from '@app/services/message.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  sending = false;

  constructor(
    private fb: FormBuilder,
    private ms: MessageService,
    private authService: AuthService,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    this.stateService.state = undefined;
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit(): void {

    if (!this.form.valid) {
      this.ms.error('', 'Invalid form');
      return;
    }

    this.sending = true;

    this.authService.forgotPassword(this.form.value.email)
      .subscribe(ok => {
        if (ok) {
          this.ms.success('Password sent to user email');
          this.stateService.state = this.form.value.email;
        } else {
          this.ms.info('User not found');
        }
        this.sending = false;

      }, err => { this.sending = false; });
  }
}
