import { ColorPickerModule } from 'ngx-color-picker';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { MatFormFieldModule, MatCheckboxModule, MatInputModule, MatButtonModule, MatIconModule, MatCardModule, MatChipsModule, MatDialogModule, MatRadioModule, MatTableModule, MatPaginatorModule, MatSelectModule, MatDatepickerModule } from '@angular/material';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ComponentsCustomModule } from '@app/components-custom/components-custom.module';
import { TagInputModule } from 'ngx-chips';
import { MatMomentDateModule } from "@angular/material-moment-adapter";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
        ColorPickerModule,
        NgxSummernoteModule,

        FuseDirectivesModule,
        FusePipesModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCardModule,
        MatChipsModule,
        MatDialogModule,
        TagInputModule,
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        ComponentsCustomModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
        ColorPickerModule,
        NgxSummernoteModule,

        FuseDirectivesModule,
        FusePipesModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCardModule,
        MatChipsModule,
        MatDialogModule,
        TagInputModule,
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        ComponentsCustomModule
    ]
})
export class FuseSharedModule {
}
