import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReleaseNote } from '@app/application/models/release-note';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Customer } from '@app/application/models/customer';
import { CustomerSearch } from '@app/application/models/customer-search';
import { CustomerFilter } from '@app/application/models/customer-filter';

@Injectable({ providedIn: 'root' })
export class CustomerService {

    constructor(
        private httpClient: HttpClient
    ) { }

    search(filter: CustomerFilter): Observable<Array<CustomerSearch>> {
        return this.httpClient.post<Array<CustomerSearch>>(`${environment.apiUrl}/customer/search`, filter);
    }

    getAll(): Observable<Array<CustomerSearch>> {
        return this.httpClient.get<Array<CustomerSearch>>(`${environment.apiUrl}/customer/all`);
    }

    getById(id: number): Observable<Customer> {
        return this.httpClient.get<Customer>(`${environment.apiUrl}/customer/${id}`);
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete<any>(`${environment.apiUrl}/customer/${id}`);
    }

    saveUpdate(data: Customer): Observable<any> {
        if (data.id > 0) {
            return this.update(data);
        } else {
            return this.save(data);
        }
    }

    private save(data: Customer): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/customer`, data);
    }

    private update(data: Customer): Observable<any> {
        return this.httpClient.put<any>(`${environment.apiUrl}/customer`, data);
    }
}
