import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { MessageService } from '@app/services/message.service';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
import { StateService } from '@app/application/services/state.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  subscriptions = new Subscription();
  isLoging = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ms: MessageService,
    private authService: AuthService,
    private stateService: StateService,
    private fuseConfigService: FuseConfigService
  ) {
    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        width: 'fullwidth',
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {
    let email = '';
    if (this.stateService.state !== undefined) {
      email = this.stateService.state;
    }

    this.loginForm = this.fb.group({
      email: [email, [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(): void {

    if (!this.loginForm.valid) {
      this.ms.error('', 'Invalid form');
      return;
    }
    this.isLoging = true;
    const { email, password } = this.loginForm.value;
    const sub = this.authService.login(email, password)
      .subscribe(msg => {
        this.isLoging = false;
        if (msg) {
          this.ms.error(' ', msg);
        } else {
          this.stateService.state = undefined;
          this.router.navigate(['app/home']);
          this.ms.show(`Bem-vindo! ${email}`);
        }
      }, err => {
        console.log(err);
        this.isLoging = false;
      });

    this.subscriptions.add(sub);
  }
}
