import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { Subject } from 'rxjs';

@Injectable()
export class CustomPaginatorIntl implements MatPaginatorIntl {
  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;
  changes = new Subject<void>();

  getRangeLabel(page: number, pageSize: number, length: number): string {
    return `${length} records`;
  }
}
