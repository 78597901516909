import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmlSanctionsComponent } from './aml-sanctions.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { AmlSanctionsRoutingModule } from './aml-sanctions.routing.module';
import { MatAutocompleteModule, MatPaginatorIntl, MatSortModule } from '@angular/material';
import { CustomPaginatorIntl } from '../shared/custom-paginator-intl';
 

@NgModule({
  declarations: [AmlSanctionsComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    AmlSanctionsRoutingModule,
    MatSortModule
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
})
export class AmlSanctionsModule { }
