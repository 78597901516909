import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TreeViewService } from '../services/tree-view.service';
import { WikiPage } from '@app/application/models/wiki-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tree-view-children',
  templateUrl: './tree-view-children.component.html',
  styleUrls: ['./tree-view-children.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeViewChildrenComponent implements OnInit, OnDestroy {

  @Input("children") children: Array<WikiPage>;
  @Output("clickChild") clickChild = new EventEmitter<WikiPage>();

  takeSubject = new Subject<boolean>();

  constructor(
    private treeViewService: TreeViewService
  ) { }

  ngOnInit() {
    this.treeViewService.onChildrenLoaded
      .pipe(takeUntil(this.takeSubject))
      .subscribe(children => {
        this.updateChildren(children);
      });
  }

  ngOnDestroy() {
    this.takeSubject.next(true);
    this.takeSubject.unsubscribe();
  }

  getCurrentIconState(child: WikiPage): string {
    return this.treeViewService.getCurrentIconState(child);
  }

  toggleOpen(child: WikiPage): void {
    this.treeViewService.toggleOpen(child);
    this.clickChild.emit(child);
  }

  setCurrentMenu(child: WikiPage) {
    this.treeViewService.setCurrentMenu(child);
  }

  isActive(child: WikiPage): boolean {
    return this.treeViewService.isPageActive(child);
  }

  onClickChild(child: WikiPage): void {
    this.clickChild.emit(child);
  }

  //
  // [ PRIVATES ]
  //

  private updateChildren(newChildren: WikiPage[]): void {
    const currentPageId = this.treeViewService.getCurrentMenu().id;
    this.updateChildrenRecursive(currentPageId, this.children, newChildren);
  }

  private updateChildrenRecursive(currentPageId: number, pages: WikiPage[], children: WikiPage[]) {
    if (!pages || pages.length == 0) {
      console.log('page not found in tree-view-children component!');
      return;
    }

    const nextNodes = new Array<WikiPage>();

    for (let i = 0; i < pages.length; i++) {
      if (pages[i].id == currentPageId) {
        pages[i].children = children;
        console.log('found the parent in tree-view-children component!');
        return;
      }

      nextNodes.push(...pages[i].children);
    }

    return this.updateChildrenRecursive(currentPageId, nextNodes, children);

  }

}
