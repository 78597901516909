import { Injectable, EventEmitter } from '@angular/core';
import { WikiPage } from '@app/application/models/wiki-page';

@Injectable({
  providedIn: 'root'
})
export class TreeViewService {

  onRequestChildrenLoad = new EventEmitter<WikiPage>();
  onChildrenLoaded = new EventEmitter<Array<WikiPage>>();
  private currentMenu: WikiPage;

  constructor() { }

  getCurrentIconState(page: WikiPage): string {
    //if ((!page.children) || page.children.length == 0) {
    if (!page.hasChildren) {
      return "notes";
    } else if (!page.open) {
      return "add";
    } else {
      return "remove";
    }
  }

  toggleOpen(page: WikiPage): void {
    if (page.hasChildren && page.hasChildren == 1) {
      
      // TOGGLE THE STATE
      page.open = !page.open;

      // ONLY REQUEST LOAD IF THE PAGE IS OPEN
      if (page.open) {
        this.onRequestChildrenLoad.emit(page);
      }
      
      //console.log('should open');
    } else {
      //console.log('stay closed');
    }
  }

  setCurrentChildren(children: Array<WikiPage>): void {
    if (this.currentMenu) {
      this.onChildrenLoaded.emit(children);
    }
  }

  setCurrentMenu(page: WikiPage) {
    this.currentMenu = page;
  }

  getCurrentMenu(): WikiPage {
    return this.currentMenu;
  }

  unsetCurrentMenu(): void {
    this.currentMenu = undefined;
  }

  isPageActive(page: WikiPage) {
    if (!this.currentMenu) {
      return false;
    }

    return (this.currentMenu.id == page.id);
  }

}
