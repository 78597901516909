import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { TreeViewChildrenComponent } from './tree-view-children/tree-view-children.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [TreeViewComponent, TreeViewChildrenComponent, ConfirmDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    TreeViewComponent,
    TreeViewChildrenComponent,
    ConfirmDialogComponent
  ],
  entryComponents: [ConfirmDialogComponent]
})
export class ComponentsCustomModule { }
