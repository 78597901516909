import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserAuth } from '@app/application/models/user-auth';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    currentUser: UserAuth;
    // Private
    private unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {FuseNavigationService} fuseNavigationService
     */
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private fuseNavigationService: FuseNavigationService,
        private authService: AuthService,
        private router: Router
    ) {
        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this.fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this.fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this.fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this.changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this.fuseNavigationService.onNavigationItemAdded,
            this.fuseNavigationService.onNavigationItemUpdated,
            this.fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this.changeDetectorRef.markForCheck();
            });

        this.authService.currentUser
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(user => {
                this.currentUser = user;
            });
    }

    onLogoutClick(): void {
        this.authService.logout();
        this.router.navigate(['login']);
    }
}
