import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserAuth } from '@app/application/models/user-auth';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<UserAuth>;
  public currentUser: Observable<UserAuth>;

  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<UserAuth>(JSON.parse(localStorage.getItem('remitecAdminUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserAuth {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string): Observable<any> {
    const obsUser = this.http.post<UserAuth>(`${environment.apiUrl}/auth`, { email, password });
    return new Observable<any>(obs => {

      obsUser.subscribe(user => {
        if (user && user.token) {
          localStorage.setItem('remitecAdminUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          obs.next('');
        } else {
          obs.next('');
        }
      }, err => {
        obs.next(err);
      });

    });
  }

  forgotPassword(email: string): Observable<boolean> {
    const hasUser = this.http.post<boolean>(`${environment.apiUrl}/auth/forgot-password`, { email });
    return hasUser;
  }

  logout(): void {
    localStorage.removeItem('remitecAdminUser');
    this.currentUserSubject.next(null);
  }
}
