import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WikiPage } from '@app/application/models/wiki-page';
import { TreeViewService } from '../services/tree-view.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeViewComponent implements OnInit, OnDestroy {

  @Input("pages") pages: Array<WikiPage>;
  @Output("clickItem") clickItem = new EventEmitter<any>();

  takeSubject = new Subject<boolean>();

  constructor(
    private treeViewService: TreeViewService
  ) { }

  ngOnInit() {

    this.treeViewService.onChildrenLoaded
      .pipe(takeUntil(this.takeSubject))
      .subscribe(children => {
        this.updateChildren(children);
      });

  }

  ngOnDestroy() {
    this.takeSubject.next(true);
    this.takeSubject.unsubscribe();
  }

  getCurrentIconState(page: WikiPage): string {
    return this.treeViewService.getCurrentIconState(page);
  }

  toggleOpen(page: WikiPage): void {
    this.treeViewService.toggleOpen(page);
    this.clickItem.emit(page);
  }

  setCurrentMenu(page: WikiPage): void {
    this.treeViewService.setCurrentMenu(page);
  }

  isActive(page: WikiPage): boolean {
    return this.treeViewService.isPageActive(page);
  }

  onClickChild(child: WikiPage): void {
    this.clickItem.emit(child);
  }

  //
  // [ PRIVATES ]
  //

  private updateChildren(children: WikiPage[]): void {
    for (let i = 0; i < this.pages.length; i++) {
      let page = this.pages[i];
      if (page.id == this.treeViewService.getCurrentMenu().id) {
        console.log('found the parent in tree-view component!');
        page.children = children;
        return;
      }
    }

    console.log('page not found in tree-view component!');
  }

}
