import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'group',
        url: '/app/home',
    },
    {
        id: 'admin',
        title: 'Management',
        type: 'group',
        children: [
            {
                id: 'customer',
                title: 'Customer',
                type: 'item',
                icon: 'person_alt',
                url: '/app/customer',
            },
            {
                id: 'release-notes',
                title: 'What is new',
                type: 'item',
                icon: 'system_update_alt',
                url: '/app/release-notes',
            },
            {
                id: 'boWikiPages',
                title: 'Knowledge base',
                type: 'item',
                icon: 'find_in_page',
                url: '/app/back-office-wiki/pages'
            },
            {
                id: 'amlsanctions',
                title: 'KYC Monitoring',
                type: 'item',
                icon: 'verified_user',
                url: '/app/aml-sanctions'
            },
        ]
    },
    {
        id: 'help',
        title: 'Tools',
        type: 'group',
        children: [
            {
                id: 'user',
                title: 'Users',
                type: 'item',
                icon: 'groups',
                url: '/app/users',
            },
            {
                id: 'setting',
                title: 'Knowledge base Setup',
                type: 'collapsable',
                icon: 'settings',
                translate: 'Settings',
                children: [
                    {
                        id: 'boWikiHome',
                        title: 'Home',
                        type: 'item',
                        icon: 'add_to_home_screen',
                        url: '/app/back-office-wiki/home'
                    },
                    {
                        id: 'boWikiEmail',
                        title: 'Email Settings',
                        type: 'item',
                        icon: 'email',
                        url: '/app/back-office-wiki/email'
                    },
                    {
                        id: 'boWikiFooter',
                        title: 'Footer',
                        type: 'item',
                        icon: 'text_format',
                        url: '/app/back-office-wiki/footer'
                    },

                    {
                        id: 'boWikiLogin',
                        title: 'Login',
                        type: 'item',
                        icon: 'person_pin',
                        url: '/app/back-office-wiki/login'
                    },
                    {
                        id: 'boWikiFiles',
                        title: 'File Settings',
                        type: 'item',
                        icon: 'attachment',
                        url: '/app/back-office-wiki/file-settings'
                    }
                ]
            },
        ]
    }
];
