import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '@app/components-custom/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  public error(message: string, title: string = 'Error'): void {
    this.toastr.error(message, title);
  }

  public success(message: string, title: string = 'Success!'): void {
    this.toastr.success(message, title);
  }

  public show(message: string): void {
    this.toastr.show(message);
  }

  public info(message: string, title: string = 'Information!'): void {
    this.toastr.info(message, title);
  }

  confirm(message: string, title: string, callBack: () => void) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        title
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res === true && callBack) {
        callBack();
      }
    });
  }
}
