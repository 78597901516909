import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    _state: any;

    get state() {
        return this._state;
    }

    set state(data) {
        this._state = data;
    }
}
