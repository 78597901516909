import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CustomerSearch } from '../models/customer-search';
import { KycMonitoringFilter } from '../models/kyc-monitoring-filter';
import { KycMonitoringSearch } from '../models/kyc-monitoring-search';
import * as XLSX from 'xlsx';
import { CustomerService } from '../services/customer.service';
import { KycMonitoringService } from '../services/kyc-monitoring.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-aml-sanctions',
  templateUrl: './aml-sanctions.component.html',
  styleUrls: ['./aml-sanctions.component.scss']
})
export class AmlSanctionsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { read: false }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.tableDataSource.paginator = paginator;
  }

  form: FormGroup;
  customers = new Array<CustomerSearch>();
  kycMonitoringSearch = new Array<KycMonitoringSearch>();
  tableDataSource = new MatTableDataSource<KycMonitoringSearch>();
  searching = true;
  displayedColumns = ['customer', 'amlQtyChecks', 'amlAmount'];

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private kycMonitoringService: KycMonitoringService,
  ) { }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
  }

  ngOnInit() {
    this.getAllCustomers();
    this.form = this.createForm();
  }

  getAllCustomers() {
    this.customerService.getAll()
      .subscribe((customers) => {
        this.customers = customers;
        this.filter();
      }, err => {
      });
  }

  tosslePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.form.controls.customer.value.length === this.customers.length) {
      this.allSelected.select();
    }
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.form.controls.customer
        .patchValue(this.customers.map(item => item.id));
      this.allSelected.select();
    } else {
      this.form.controls.customer.patchValue([]);
    }
  }

  exportAsExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const date = new Date();
    XLSX.writeFile(wb, 'KycMonitoringExport.xlsx');
  }
  exportAsExcelDetailed() {
    let filter: KycMonitoringFilter;
    filter = (this.form.getRawValue() as KycMonitoringFilter);

    this.kycMonitoringService.export(filter)
      .subscribe((data) => saveAs(data, 'KycMonitoringExportDetailed.xlsx')
        , err => {
          console.log(err);
        });
  }

  filter() {
    this.searching = true;
    let filter: KycMonitoringFilter;
    filter = (this.form.getRawValue() as KycMonitoringFilter);

    this.kycMonitoringService.search(filter)
      .subscribe((data) => {
        this.kycMonitoringSearch = data;
        this.tableDataSource.data = data;
        this.searching = false;
      }, err => {
        this.searching = false;
      });
  }

  clearFilter() {
    this.form = this.createForm();
    this.filter();
  }

  createForm(): FormGroup {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return this.fb.group({
      customer: [[]],
      startDate: [firstDay],
      endDate: [lastDay],
    });
  }
}
